<template>
  <!-- 聊天客服->聊天内容 -->
  <div class="chat-content" ref="chatContent">
    <div v-if="list.length > 0">
      <div class="userName" ref="userName">
        <span>{{ groupData.name }}</span>
      </div>
      <div class="content" ref="content">
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
            :class="[{ left: item.is_my !== 0 }, { right: item.is_my === 0 }]"
          >
            <p class="realTime" v-if="index === 0">{{ item.created_date }}</p>
            <p
              class="realTime"
              v-else-if="item.created_time - list[index - 1].created_time > 120"
            >
              {{ item.created_date }}
            </p>
            <!-- <br> -->
            <div class="dialogue">
              <img
                v-if="item.is_my === 0"
                :src="myData.head_img"
                alt
                class="portrait"
              />
              <img v-else :src="groupData.logo" alt class="portrait" />
              <div class="word">
                <div class="horn"></div>
                <TextMessage
                  v-if="item.text_type === 1"
                  :text="item.text"
                ></TextMessage>
                <PictureMessage
                  v-else-if="item.text_type === 2"
                  :text="item.text"
                ></PictureMessage>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="input-box" ref="inputBox">
        <div class="send-map">
          <div class="send-pictures" @click="startImg">
            <img src="@/assets/chat/icon.png" alt />
          </div>
        </div>
        <!-- <el-input v-model="input" placeholder="请输入内容"></el-input> -->
        <!-- <textarea name="" id="" cols="30" rows="10"></textarea> -->
        <el-input
          type="textarea"
          :rows="5"
          resize="none"
          placeholder="请输入内容"
          v-model="msg"
          class="textarea"
          @keydown.enter.native.prevent="sendMsg"
        ></el-input>
        <el-button type="primary" class="sending" @click="sendMsg"
          >发送</el-button
        >
      </div>
    </div>
    <div class="contents" v-else>
      <div class="blank">
        <img src="@/assets/chat/blank.png" alt="" />
        <span>暂无数据</span>
      </div>
    </div>
    <OssImg ref="ossImg" @getImages="sendImg" v-show="aaa"></OssImg>
  </div>
</template>

<script>
import TextMessage from "@/views/chat/modules/content/text-message/text-message.vue";
import PictureMessage from "@/views/chat/modules/content/picture-message/picture-message.vue";
import tools from "@/common/js/tools";
import OssImg from "@/components/oss/oss-img";

export default {
  props: {
    socket: {
      type: Object,
    },
  },
  components: {
    TextMessage,
    PictureMessage,
    OssImg,
  },
  data() {
    return {
      textarea: "",
      aaa: "",
      goodsId: 0,
      usedId: 0,
      shopId: 0,
      uId: 0,
      groupType: 3,
      groupId: 0,
      list: [],
      page: 1,
      msg: "",
      imgUrl: "",
      myData: {
        id: 0,
        m_id: 0,
        head_img: "",
        nickname: "",
      },
      groupData: {
        id: 0,
        logo: "",
        m_id: "",
        name: "",
      },
      endId: 0,
    };
  },
  mounted() {
    //事件注册
    let _that = this;
    //接收推送消息
    this.socket.on("getImInfo", (ret) => {
      console.log("接收推送消息=============", ret);
      if (ret.status * 1 === 1) {
        _that.myData = ret.data.user;
        _that.groupData = ret.data.group;
        _that.groupId = ret.data.group.id;
        _that.uId = ret.data.group.to_id;
        _that.list = [];
        _that.page = 1;
        _that.endId = 0;
        _that.setImList(ret.data.items);
        _that.setRead();
        setTimeout(function () {
          let container = _that.$refs.content;
          if (container) {
            container.scrollTop = container.scrollHeight + 150;
          }
        }, 10);
      } else {
        tools.error(ret.msg);
      }
    });
    //绑定获取消息通知
    this.socket.on("sendMsg", (ret) => {
      if (ret.status * 1 === 1) {
        _that.setSendMsg(ret.data);
      } else {
        tools.error(ret.msg);
      }
    });
    //获取历史聊天信息
    this.socket.on("getMsgList", (ret) => {
      console.log(ret);
      if (ret.status * 1 === 1) {
        _that.setImList(ret.data);
      } else {
        tools.error(ret.msg);
      }
    });
    //将消息标记为已读
    this.socket.on("setRead", (ret) => {
      if (ret.status * 1 === 1 && ret.data.look_id * 1 !== _that.myData.id * 1) {
        _that.list.forEach((val, key) => {
          val.status = 1;
        });
      }
    });
  },
  methods: {
    setIm(groupId) {
      //设置聊天ID
      console.log("this.groupType------------", this.groupType);
      this.groupId = groupId;
      this.endId = 0;
      //获取Im聊天组信
      this.socket.emit("getImInfo", {
        group_id: this.groupId,
        shop_id: this.shopId,
        handle_id: this.uId,
        group_type: this.groupType,
      });
    },
    setSendMsg(data) {
      this.$emit("newImGroup",{
        id:data.groupId*1,
        group_type:3,
        shopId:this.shopId,
      });
      if (this.groupId * 1 !== data.groupId * 1) {
        return false;
      }
      if (data.mId * 1 === this.myData.m_id * 1) {
        data.is_my = 0;
        this.setRead();
      } else {
        data.is_my = 1;
      }
      data.text_type= data.textType;
      this.list.push(data);
      //验证是否在底部，在部就刷新到最底页面
      setTimeout(() => {
        let container = this.$refs.content;
        if (container) {
          let userName = this.$refs.userName.scrollHeight;
          let inputBox = this.$refs.inputBox.scrollHeight;
          let chatContent = this.$refs.chatContent.scrollHeight;
          let contentH = chatContent - (userName + inputBox);
          if (contentH <= (container.scrollHeight - container.scrollTop) / 2) {
            container.scrollTop = container.scrollTop;
          } else {
            container.scrollTop = container.scrollHeight;
          }
        }
      }, 10);
    },
    setRead() {
      this.socket.emit("setRead", {
        group_id: this.groupId,
        look_id: this.myData.id,
      });
    },
    setImList(list) {
      let _that = this;
      list.forEach((val, key) => {
        if (val.m_id * 1 === _that.myData.m_id * 1) {
          val.is_my = 0;
        } else {
          val.is_my = 1;
        }
        _that.list.unshift(val);
      });
    },
    sendMsg() {
      //发送文本消息
      if (this.msg === "") {
        tools.error("请输入聊天内容");
        return false;
      }
      this.sendSocketMsg(this.msg, 1);
      this.msg = "";
      //   let container = this.$refs.content;
      //   console.log(container.scrollTop + "-----------scrollTop");
      //   console.log(container.scrollHeight + "-----------scrollHeight");
      //   let userName = this.$refs.userName.scrollHeight;
      //   let inputBox = this.$refs.inputBox.scrollHeight;
      //   let chatContent = this.$refs.chatContent.scrollHeight;
      //   let contentH = chatContent - (userName + inputBox);
      //   console.log(contentH+'-------聊天高度');
      //   console.log(((container.scrollHeight)-(container.scrollTop))/2 +'--------差');
    },
    startImg() {
      this.$refs.ossImg.startImg();
    },
    sendImg(imgUrl) {
      this.imgUrl = imgUrl;
      if (this.imgUrl === "") {
        tools.error("请选择图片");
        return false;
      }
      this.sendSocketMsg(this.imgUrl, 2);
      this.imgUrl = "";
    },
    sendSocketMsg(text, textType) {
      //发送消息
      this.socket.emit("sendMsg", {
        group_id: this.groupId,
        text_type: textType,
        handle_id: this.uId,
        text: text,
      });
    },
    // onkey() {
    //   this.textarea = "";
    // },
    // blank() {
    //   if (this.list.length > 0) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.realTime {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  font-weight: 400;
  color: #666;
}
.chat-content {
  width: calc(100% - 385px);
  height: calc(100vh - 81px);
  float: right;
  .userName {
    width: 100%;
    height: 64px;
    overflow: hidden;
    border-bottom: 1px solid #e4e4e4;
    span {
      font-size: 24px;
      color: #333;
      font-weight: bold;
      display: block;
      margin: 15px 0 0 48px;
    }
  }
  .content {
    width: 100%;
    height: calc(100vh - 349px);
    // padding-top: 40px;
    overflow: auto;
    ul {
      padding-top: 40px;
      li {
        width: 100%;
        height: auto;
        // background: pink;
        margin: 0 0 48px 0;
      }
    }
  }
  .contents {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    .blank {
      width: 342px;
      height: auto;
      text-align: center;
      // background: chartreuse;
      margin: 15% auto;
      img {
        display: block;
      }
      span {
        text-align: center;
      }
    }
  }
  .input-box {
    width: 100%;
    height: 202px;
    // background: pink;
    border-top: 1px solid #e4e4e4;
    position: relative;
    .send-map {
      width: 100%;
      height: 72px;
      // background: olive;
      overflow: hidden;
      .send-pictures {
        width: 32px;
        height: 32px;
        margin: 20px 0 0 41px;
        img {
          width: 32px;
          height: 32px;
          display: block;
        }
      }
      .send-pictures:hover {
        background: #ccc;
      }
    }
    .inputs {
      width: 100%;
      height: 130px;
      border: none;
    }
    /deep/ .el-textarea__inner {
      border: none;
    }
    .sending {
      width: 80px;
      height: 40px;
      position: absolute;
      right: 25px;
      bottom: 10px;
    }
  }
}
.left {
  position: relative;
  display: flex;
  .dialogue {
    width: 100%;
    height: auto;
    .portrait {
      width: 65px;
      height: 65px;
      margin: 0 19px 0 40px;
      float: left;
      border-radius: 5px;
    }
    .word {
      position: relative;
      width: auto;
      height: auto;
      background: #f3f3f3;
      display: inline-block;
      // line-height: 65px;
      max-width: 840px;
      border-radius: 5px;
      padding: 12px 20px;
      // text-align: center;
      .horn {
        position: absolute;
        top: 9px;
        left: -19px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 8px solid transparent;
        border-right: 12px solid #f3f3f3;
        border-bottom: 8px solid transparent;
      }
    }
  }
}
.right {
  position: relative;
  display: flex;
  justify-content: flex-end;
  .dialogue {
    width: auto;
    height: 100%;
    // background: chartreuse;
    .portrait {
      width: 65px;
      height: 65px;
      margin: 0 19px 0 41px;
      float: right;
    }
    .word {
      position: relative;
      width: auto;
      height: auto;
      background: #d9f4fd;
      display: inline-block;
      max-width: 840px;
      border-radius: 5px;
      // line-height: 64px;
      padding: 20px 20px;
      // text-align: left;

      .horn {
        position: absolute;
        top: 13px;
        right: -19px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 8px solid #d9f4fd;
        border-right: 12px solid transparent;
        border-bottom: 8px solid transparent;
      }
      span {
        color: #333;
        font-size: 24px;
        // margin: 0 0 0 53px;
        padding: 0 20px 0 20px;
      }
    }
  }
}
</style>
